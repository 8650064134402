import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Bar Work`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Bar Muscle Up +`}</p>
    <p>{`1-CTB Pullup +`}</p>
    <p>{`1-Pullup +`}</p>
    <p>{`1-T2B +`}</p>
    <p>{`1-K2E`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`Buy In: 500M Ski Erg`}</p>
    <p>{`Partition out as needed:`}</p>
    <p>{`75-Pullups`}</p>
    <p>{`125-Pushups`}</p>
    <p>{`150-Situps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      